import React from "react";
import ImageSection from "src/Components/ImageSection";

const ContactUs = () => {
  return (
    <>
      <ImageSection
        src="images/contact_web_small.jpg"
        height={200}
        title="Contact Us"
      />
      <div style={{ marginTop: 20 }}>
        A. Adili Tax & Accounting
        <br />
        15 Meadow St, PO Box 915
        <br />
        Litchfield, CT 06759 <br />
        <br />
        Phone: <b>860-567-2145</b> <br />
        Fax: <b>860-567-2153</b> <br />
        <b>ardiana@adilitax.com</b>
      </div>
    </>
  );
};

export default ContactUs;
