import React from "react";
import ImageSection from "src/Components/ImageSection";

const TaxCenter = () => {
  return (
    <>
      <ImageSection
        src="images/taxcenter_web_small.jpg"
        height={200}
        title="Tax Center"
      />
      <div>
        <ul>
          <li>Track your refund (Federal and state)</li>
          <li>Tax due dates</li>
          <li>Tax Rates</li>
          <li>Records retention guide</li>
        </ul>
      </div>
    </>
  );
};

export default TaxCenter;
