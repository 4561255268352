import React from "react";
import {
  createStyles,
  Header,
  UnstyledButton,
  Group,
  Burger,
  Container,
  Transition,
  Paper,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Image } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  inner: {
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  linkLabel: {
    marginRight: 5,
  },
  dropdown: {
    position: "absolute",
    top: 56,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const HeaderBar = (props) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    { link: "/home", label: "Home" },
    { link: "/about", label: "About" },
    { link: "/services", label: "Services" },
    { link: "/taxcenter", label: "Tax Center" },
    { link: "/contact", label: "Contact Us" },
  ];

  const items = links.map((link) => {
    const selected = location.pathname.includes(link.link);
    return (
      <UnstyledButton
        key={link.label}
        sx={(theme) => ({
          display: "block",
          lineHeight: 1,
          padding: "8px 12px",
          borderRadius: theme.radius.sm,
          textDecoration: "none",
          color: selected ? "#fff" : theme.colors.gray[7],
          fontSize: theme.fontSizes.sm,
          fontWeight: selected ? 500 : 500,
          backgroundColor: selected ? "#9fbba0" : "transparent",

          "&:hover": {
            backgroundColor: selected ? "#9fbba0" : theme.colors.gray[0],
            color: selected ? "#fff" : theme.colors.gray[7],
            cursor: selected ? "default" : "pointer",
          },
          [theme.fn.smallerThan("sm")]: {
            borderRadius: 0,
            padding: theme.spacing.md,
            width: "100%",
          },
        })}
        onClick={() => {
          navigate(link.link);
        }}
      >
        {link.label}
      </UnstyledButton>
    );
  });

  return (
    <Header height={56}>
      <Container>
        <div className={classes.inner}>
          <div style={{ width: 150 }}>
            <Image src="logo.png" />
          </div>
          <Group spacing={5} className={classes.links}>
            {items}
          </Group>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />
          <Transition
            transition="pop-top-right"
            duration={200}
            mounted={opened}
          >
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {items}
              </Paper>
            )}
          </Transition>
        </div>
      </Container>
    </Header>
  );
};

export default HeaderBar;
