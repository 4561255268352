import {
  createStyles,
  Container,
  Group,
  ActionIcon,
  Text,
} from "@mantine/core";
import { IconBrandFacebook } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },
}));

const Footer = (props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Text color="dimmed" size="sm">
          © 2022 A. Adili Tax & Accounting
        </Text>
        <Group spacing={0} className={classes.links} position="right" noWrap>
          <ActionIcon size="lg">
            <IconBrandFacebook
              size={18}
              stroke={1.5}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100063494592470",
                  "_blank"
                )
              }
            />
          </ActionIcon>
        </Group>
      </Container>
    </div>
  );
};

export default Footer;
