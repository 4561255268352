import React from "react";
import ImageSection from "src/Components/ImageSection";

const Home = () => {
  return (
    <>
      <ImageSection
        src="images/home_web_small.jpg"
        height={200}
        img="logo.png"
        bg="rgba(255, 255, 255, 0.7)"
      />

      <h1>An investment, not an expense…</h1>
      <div>
        At A. Adili Tax & Accounting, we strive to provide our clients with a
        return on their investment in working with us. We dig deep to understand
        processes, decisions and events that make up their business and
        financial environments. Our goal is to go further than standard
        services. We take the time to understand our clients’ needs and help
        guide them successfully. Here on our website you’ll learn more about our
        mission, approach and philosophy.
      </div>
      <div className="sectionDivider"></div>
      <h1>Our Practice</h1>
      <div>
        A. Adili Tax & Accounting provides quality, efficiency and consistency
        to your tax, financial, accounting and technology needs. We can enhance
        your personal or business financial health and help you avoid audits and
        penalties at tax time. Our accountants offer year-round experience and
        knowledge to help you solve problems and take advantage of
        opportunities. We have experience in several different industries and
        are committed to providing state of the art solutions. Our services are
        completely scalable to your requirements.
      </div>
    </>
  );
};

export default Home;
