import React from "react";
import ImageSection from "src/Components/ImageSection";

const About = () => {
  return (
    <>
      <ImageSection
        src="images/about_web_small.jpg"
        height={250}
        title="About Us"
        description="Our mission is to ensure the continued financial health of your business
        using sound and standard accounting methods and practices. We take an
        approach that focuses on detail to eliminate mistakes and maximize
        returns. We bring years of experience and knowledge to your financial
        requirements and also continue to investigate the latest standards, laws
        and practices."
      />

      <h1>Our Philosophy</h1>
      <div>
        At A. Adili Tax & Accounting, we believe that consistency is the path to
        quality in accounting. We've established a clear set of guidelines and
        practices. Our accountants apply these high standards to your
        bookkeeping, tax and related requirements. This helps to ensure your
        records are completely correct every day, every week and every year.
        <br />
        <br />
        We also believe it's important that you understand the financial records
        and reports of your business. We give you the tools and take time to
        answer your questions and explain all the numbers.
      </div>
      <div className="sectionDivider"></div>
      <h1>Our Strategy</h1>
      <div>
        In addition to reviewing your books and previous tax returns, we also
        take the time to learn about your business model and future goals. This
        allows us to customize our services to meet your financial requirements.
        Our goal is to understand your business and earn your trust.
        <br />
        <br />
        Our strategy is to learn about your business model and apply the
        appropriate accounting standards. Rather than taking a “one-size fits
        all” approach, we tailor our services to your specific situation and
        needs. We can design an ongoing solution that fits and guide you in the
        latest technology to give your business a competitive advantage.
      </div>
    </>
  );
};

export default About;
