import React from "react";
import ImageSection from "src/Components/ImageSection";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <ImageSection
        src="images/services_web_small.jpg"
        height={200}
        title="Services"
      />
      <h1>Tax Planning and Preparation</h1>
      <div>
        <b>Tax Planning</b> – The best way to approach taxes is to plan ahead.
        Whether you're a business or individual, we can analyze your current and
        future revenue and expenses to derive a comprehensive plan with
        tax-saving strategies. We'll keep you informed of tax laws throughout
        the year to minimize your tax liabilities and maximize your future
        opportunities. With smart tax planning meetings in the Spring and/or
        Fall, we continually advise you on your tax situation.
        <br />
        <br />
        <b>Tax Preparation</b> – Tax filing can be a stressful time. We take the
        hassle out of tax preparation. We’ll review your income and expenses to
        maximize your deductions and eliminate mistakes. We can even review your
        previous returns for errors.
        <br />
        <br />
        <b>
          Our clients also have the added benefit of access to their tax
          documentation via a secure personal login on our website. This means
          you have 24/7 access to your tax returns and financial information.
          <Link to="/contact" style={{ marginLeft: 5 }}>
            Contact us for details
          </Link>
        </b>
      </div>
      <div className="sectionDivider"></div>
      <h1>Business Services</h1>
      <div>
        Being a small business owner can get extremely busy with all the details
        that go into running the daily activities of a business. Successful
        entrepreneurs know that accounting can provide insights into their
        business that can help them make better decisions. Our services are
        designed to make our entrepreneurs' lives easier and provide actionable
        knowledge which can provide a competitive advantage when making these
        decisions.
        <br />
        <br />
        <b>Accounting Services</b> – This includes a number of essential
        business services such as invoicing and billing, bank reconciliation,
        income statement review and balance sheet maintenance. These services
        keep you informed about your business and ensure financial health.
        <br />
        <br />
        <b>Bookkeeping</b> – It’s important to keep meticulous records and
        balance the books on a regular basis. A company’s books provide
        information about the ongoing health of the business and are required
        for certain tasks such as valuations and audits.
        <br />
        <br />
        <b>Payroll</b> – Payroll is more than just making sure people get paid.
        It also involves deductions, maintenance and reporting. Many companies
        spend hours each week on payroll functions. Outsourcing this function
        saves you time and money and allows you to focus on your core business.
      </div>
    </>
  );
};

export default Services;
