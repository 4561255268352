import React from "react";
import { Image } from "@mantine/core";

const ImageSection = ({
  title,
  description,
  src,
  height,
  img,
  bg = "rgba(0, 0, 0, 0.7)",
}) => {
  return title || description || img ? (
    <div
      style={{
        width: "100%",
        height: height,
        overflow: "hidden",
        borderRadius: 10,
        position: "relative",
      }}
    >
      <Image height={height} src={src} fit="cover" />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          backgroundColor: bg,
        }}
      >
        {img && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={img} height={height - 70} fit="contain" />
          </div>
        )}
        {description ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 32, marginBottom: 5 }}>
              {title}
            </div>
            <div style={{ fontSize: 15 }}>{description}</div>
          </div>
        ) : (
          title && (
            <div style={{ fontWeight: "bold", fontSize: 32 }}>{title}</div>
          )
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: height,
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      <Image height={height} src={src} fit="cover" />
    </div>
  );
};

export default ImageSection;
