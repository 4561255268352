import React, { useEffect } from "react";
import WebFont from "webfontloader";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import Header from "src/Components/Header";
import Footer from "src/Components/Footer";
import HomePage from "src/Pages/Home";
import AboutPage from "src/Pages/About";
import ServicesPage from "src/Pages/Services";
import TaxCenterPage from "src/Pages/TaxCenter";
import ContactUsPage from "src/Pages/ContactUs";

const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans:300,400,500,600,700,800"],
      },
    });
  }, []);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Router>
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Header />
          <div
            style={{
              width: "100%",
              display: "flex",
              overflow: "auto",
              flex: 1,
            }}
          >
            <div style={{ flex: 1 }}></div>
            <div style={{ maxWidth: 968, width: "100%" }}>
              <div style={{ padding: 20 }}>
                <Routes>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/taxcenter" element={<TaxCenterPage />} />
                  <Route path="/contact" element={<ContactUsPage />} />
                  <Route path="*" element={<Navigate to="/home" replace />} />
                </Routes>
              </div>
            </div>
            <div style={{ flex: 1 }}></div>
          </div>
          <Footer />
        </div>
      </Router>
    </MantineProvider>
  );
};

export default App;
